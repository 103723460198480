@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Montserrat';
    src: url(./fonts/Montserrat/Montserrat-Regular.ttf);
    font-weight: 500;
}
@font-face {
    font-family: 'Montserrat';
    src: url(./fonts/Montserrat/Montserrat-Black.ttf);
    font-weight: 900;
}
@font-face {
    font-family: 'Montserrat';
    src: url(./fonts/Montserrat/Montserrat-ExtraBold.ttf);
    font-weight: 800;
}
@font-face {
    font-family: 'Montserrat';
    src: url(./fonts/Montserrat/Montserrat-Bold.ttf);
    font-weight: 700;
}

body {
    background-color: #0E0D18;
    overflow-x: hidden;
}

* {
  scroll-behavior: smooth !important;
}


.timeline {
    list-style: none;
    margin: 200px 0 0;
    border-top: var(--thickness) solid white;
    display: table;
    border-spacing: 30px 0;
    transform: translateY(200px);
    padding-left: 600px;
  }
  
  
  .timeline li {
    display: table-cell;
    position: relative;
    min-width: 200px;
  }
  
  
  .circle {
    margin: 0 auto;
    top: -24px;
    left: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: #01060F;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  
  
  .inner-circle {
    width: 30px;
    height:30px;
    border: var(--thickness) solid #2CAEC3;
    border-radius: 50%;
  }
  
  .content {
    text-align: center;
    position: relative;
    
  }
  .avatar {
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 50%;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 30px;
    background: #fffff0;
  }
  .content:before {
    content: '';
    width: 50px;
    height: var(--height);
    left: 50%;
    margin-left: -2px;
    position: absolute;
  }
  
  li.down .avatar {
    top: 80px;
    left: 100px;
  }
  
  li.down .content:before {
    border-left: var(--thickness) solid white;
  }
  
  li.up .avatar {
    background: #fffff0;
    margin-top: -20px;
  }
  
  li.up .content {
    margin-top: calc(var(--height) * -1);
  }
  
  li.up .content:before {
    border-left: var(--thickness) solid white;
  }
  
  